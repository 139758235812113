:root {
    --color-primary: #f07c2d;
    --color-light-bg: #fcfcfc;
    --color-dark-bg: #1b1c1b;
    --color-black: #222;
    --color-success: #5c9d5c;
    --color-red: #ff3333;
    --color-accent: #007CFF;

    --color-dark: #1C1C1C;
    --color-extra-dark: #0A0706;

    --color-text-secondary--light: #626C77;

    --color-gray: #808384;
    --color-gray-input: rgba(181, 178, 176, 0.5);

    --box-shadow-default: 0 4px 24px rgb(0 0 0 / 12%);
}
