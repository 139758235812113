body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none;
}

.CalendarDay__selected {
    background-color: var(--color-primary) !important;
    border-color: #e4e7e7 !important;
}

.CalendarDay__selected_span {
    background-color: var(--color-primary) !important;
    border-color: #e4e7e7 !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid var(--color-primary) !important;
}

.SingleDatePicker {
    margin-top: 8px;
    width: 100%;
}

.SingleDatePicker.SingleDatePickerInput {
    display: block;
}

.SingleDatePickerInput.SingleDatePickerInput__withBorder {
    display: block;
    border: none;
}

.SingleDatePicker .DateInput {
    width: 100%;
    background: transparent;
}

.SingleDatePicker .DateInput_input {
    font-size: 18px;
    line-height: normal;
    width: calc(100% - 32px);
    padding: 16px;
    background: transparent;
    border: none;
}

.SingleDatePickerInput.SingleDatePickerInput__withBorder .DateInput_input {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
}

.SingleDatePicker .DateInput_input__disabled {
    font-style: normal;
}

.SingleDatePicker .DateInput_input__focused {
    border: 1px solid var(--color-primary)!important;
}
