@import 'fonts.css';

html,
body {
    width: 100%;
    font-family: var(--font-default);
}

a {
    font-family: var(--font-default);
    color: var(--color-primary);
    font-size: 16px;
    transition: opacity 0.4s;
}

a:hover {
    opacity: 0.8;
}

#root {
    width: 100%;
}
