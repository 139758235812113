@import '../../colors.css';

.container {
    display: flex;
    flex-direction: column;
    background-color: var(--color-light-bg);
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.box {
    padding: 48px 144px;
    box-shadow: var(--box-shadow-default);
    border-radius: 10px;
    background-color: #fff;
}

.title {
    text-align: center;
    letter-spacing: 1px;
}

.text {
    text-align: center;
}

.link {
    display: block;
    text-align: center;
    margin-top: 24px;
}

.signOutBtn {
    position: absolute;
    top: 48px;
    right: 48px;
    padding: 8px 16px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
}

.signOutBtnIcon {
    margin-right: 4px;
}
